/* General input and select styling */
input[type="text"],
input[type="number"],
select {
    border: none;
    box-shadow: none;
    font-family: Arial, sans-serif; /* Consistent font style */
    font-size: 16px; /* Consistent font size */
    background-color: #F0F2F5; /* Light grey background for input fields */
}

/* Specific style for the number input (Pages per Search) */
input[type="number"] {
    -webkit-appearance: textfield; /* Removes default controls in WebKit browsers */
    -moz-appearance: textfield; /* Removes default controls in Firefox */
}

/* Styling for custom up/down arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Removes default arrows for Firefox */
input[type="number"]::-moz-focus-inner,
input[type="number"]::-moz-focus-outer {
    border: 0;
    -moz-appearance: textfield;
}

/* For Internet Explorer and Edge */
input[type="number"] {
    -ms-clear: none;
    -ms-reveal: none;
}

/* For all browsers, ensure that cursor text is shown, not a pointer or other type */
input[type="number"] {
    cursor: text;
}


input[type="number"]::after {
    content: '⬆⬇'; /* Custom arrows */
    font-size: 12px; /* Smaller arrow icons */
    color: #555; /* Icon color */
    position: absolute;
    right: 10px;
    top: 2px;
}

/* Model selection dropdown with custom arrow */
.option select {
    -webkit-appearance: none; /* Removes default dropdown styling */
    -moz-appearance: none; /* Removes default dropdown styling */
    appearance: none; /* Removes default dropdown styling */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>'); /* Custom SVG arrow */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Positioning the arrow to the right center */
    background-size: 12px; /* Icon size */
    padding-right: 30px; /* Padding to avoid text overlaying on arrow */
}

/* Update placeholder color across all input fields */
input::placeholder {
    color: #aaa; /* Lighter placeholder text */
}

/* Footer styling adjustments to make it on the same row and lighter */
.footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Aligns items vertically */
    margin-top: 2em;
    color: #ccc; /* Lighter grey text for #pages and price */
    font-size: 16px; /* Adjusts the font size if needed */
}

/* Search button styling enhancements */
.search-button {
    width: 30%; /* Bigger width */
    height: 50px; /* Specific height to ensure consistency */
    padding: 0; /* Removes padding that could affect vertical centering */
    color: white; /* White text */
    font-size: 20px; /* Bigger text size */
    font-weight: bold; /* Makes the text bold */
    border-radius: 10px; /* Slightly rounded corners for aesthetics */
    cursor: pointer; /* Indicates it's clickable */
    line-height: 50px; /* Same as the height to center text vertically */
    text-align: center; /* Ensures text is centered horizontally */
    display: inline-block; /* Allows the use of text-align and line-height for centering */
    border: none; /* No border */
}
/* Styles to align search button with footer info */
.search-button-container {
    display: flex;
    justify-content: space-between; /* Spreads out button and footer info */
    align-items: center;
    width: 100%; /* Takes full width of its container */
    margin-top: 20px; /* Adds space above the container */
}

/* App.css */
.search-container {
    width: 40%;
    margin: 10% auto; /* Increased top and bottom margin */
    padding: 3% 3%; /* Increased padding for more spacing inside */
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.search-header,
.logo,
h1,
.subheading {
    margin-bottom: 1em; /* Increased bottom margin for all headers and subheadings */
}

.logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

h1 {
    font-size: 32px;
    color: #333;
}

.subheading {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: 0.5em;
}

.search-bar {
    position: relative;
    margin-bottom: 30px;
}

.search-bar input {
    width: 100%;
    padding: 10px;
    border-radius: 8px; /* Less rounded corners */
    border: 1px solid #ccc;
    font-size: 16px;
    background-color: #F0F2F5; /* Light grey background */
}

.options-heading {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 2em;
}

/* Other styles remain the same */

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em; /* Increased bottom margin to space out options more */
    width: 100%;
}

.option-column {
    flex-direction: column;
    align-items: flex-start; /* Aligns items to the start of the flex container */
}

.option input[type="checkbox"],
.option input[type="number"],
.option select {
    margin-bottom: 1em; /* Added bottom margin to space out inputs within each option */
}

.option input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 10px;
}

/* Model Selection Field Styling */
.option select {
    width: 100%; /* Ensures select fits the width of the container */
    padding: 12px 10px; /* Adjust to match size */
    border-radius: 4px; /* Adjust to match style */
    border: 1px solid #d9d9d9; /* Adjust to match color and width */
    background-color: #fff; /* Adjust to match background color */
    font-size: 16px; /* Adjust to match font size */
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); /* Adjust to add or remove shadow */
    -webkit-appearance: none; /* Removes default styling on iOS */
    -moz-appearance: none; /* Removes default styling in older Firefox */
    appearance: none; /* Standard way to remove default styling */
    background-image: url('data:image/svg+xml;charset=US-ASCII,...'); /* Optional: Add an arrow icon if you want */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position for the arrow icon */
    background-size: 12px; /* Size for the arrow icon */
}

.option input[type="number"] {
    width: 100%; /* Full width of the container */
    padding: 15px 10px; /* Taller input */
    border-radius: 15px; /* More rounded */
    border: 1px solid #ccc;
    background-color: #F0F2F5; /* Light grey background */
}


.option input[type="number"],
.option select {
    width: 100%; /* Ensures inputs/selects fit the width of the container */
}

/* Increase specificity for search button to override other styles */
.search-button-container .search-button {
    background-color: #007bff; /* Adjust color code to match the Figma design */
    box-shadow: none; /* Remove shadow if present */
    border: none; /* Ensure there's no border */
}


/* Increase specificity for search button to override other styles */
.search-button-container:hover .search-button:hover {
    background-color: #0052ab; /* Adjust color code to match the Figma design */
    box-shadow: none; /* Remove shadow if present */
    border: none; /* Ensure there's no border */
}


/* Remove borders from inputs and selects for a cleaner look */
input[type="text"],
input[type="number"],
select {
    border: none;
    box-shadow: none;
}

/* Update this to match the font size and weight from the Figma design */
.search-container .subheading,
.option label {
    font-size: 16px; /* Example size, adjust as needed */
    font-weight: 400; /* Normal weight; adjust if the design is different */
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.search-bar input::placeholder {
    color: #ccc; /* Adjust color to match design */
}

/* Align footer info text */
.footer-info div {
    margin-right: 10px; /* Adjust the margin as necessary to align with the button */
}

/* New styles for number input container and icons */
.number-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.number-input-container input[type="number"] {
    width: 100%;
    padding: 15px 10px;
    padding-right: 40px; /* Sufficient padding to prevent overlap with icons */
    border-radius: 15px;
    border: 1px solid #ccc;
    background-color: #F0F2F5;
    -webkit-appearance: none; /* Removes default number input styles */
}

.icon-container {
    display: flex;
    flex-direction: column; /* Icons stacked vertically */
    justify-content: center; /* Center icons vertically */
    align-items: center; /* Align icons on the x-axis */
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    padding: 5px 0; /* Padding to keep icons away from the input edges */
}

.icon {
    color: #555;
    cursor: pointer;
    font-size: 16px; /* Appropriate size for visibility */
    padding: 2px; /* Reduced padding to decrease space between icons */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none; /* Removes default arrows */
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);  /* Darken background more for better focus on modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  /* High z-index to ensure it's on top */
}


.modal-content button {
    padding: 10px; /* Comfortable padding for clicking */
    margin: 10px 5px; /* Sufficient space between buttons */
    font-size: 16px; /* Consistent font size with other buttons */
    border: none; /* No borders */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    outline: none; /* Remove focus outline */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.modal-cancel-button {
    background-color: #eae9e9;
}
.modal-confirm-button{
    background-color: #007bff; /* Matching the search button color */
    color: white; /* Text color */
}

.modal-buttons{
    display: flex; /* Align buttons horizontally */
    justify-content: center; /* Center buttons */

}

.modal-confirm-button:hover {
    background-color: #0056b3; /* Darker blue on hover, matching the search button */
}
.modal-cancel-button:hover {
    background-color: #8f8f8f; /* Darker blue on hover, matching the search button */
}

.modal-content button:focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue glow to indicate focus, accessibility feature */
}

/* Ensure buttons are centered and spaced evenly */
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons horizontally */
    justify-content: center; /* Center content vertically */
}


.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 40%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Optional: adds shadow for better visibility */
}

.tooltip {
    position: relative;
    display: flex;
    align-items: center;  /* This will vertically center the icon with the text */
    cursor: pointer;
    padding:5px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip exactly above the icon */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.label-with-tooltip {
    display: flex;
    align-items: center; /* Ensure vertical centering of all items in the container */
    gap: 5px; /* Add a gap between label and icon for better spacing */
}



.sign-out-button {
    position: absolute; /* Absolute positioning */
    right: 20px; /* 20px from the right edge of the .search-page container */
    top: 20px; /* 20px from the top of the .search-page container */
    padding: 10px 20px;
    background-color: #e1e1e1; /* Bright blue background */
    color: #333; /* White text */
    border: none;
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Appropriate font size */
    cursor: pointer; /* Cursor indicates clickable */
    box-shadow: none; /* No shadow for a flat design */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.sign-out-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.error-message {
    color: red;
    margin-top: 8px;
}
