.cancel-page {
    text-align: center;
    padding: 50px;
    margin:50px;
}

.back-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
