.progress-bar, .progress{
    border-radius: 100px;
}

.progress-bar {
    height: 10px;
    background-color: black;
    transition: width 0.6s ease;
}

.progress {
    background-color: lightgray;
    width: 50%; /* Adjust according to your preference */
    margin: 0 auto; /* Adjust to match style */
}

.loading-screen {
    padding-top: 10%;
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: black; /* Default text color */
    position: relative; /* Needed for absolute positioning of children */
    min-height: 100vh;  /* Make the div take at least the full height of the viewport */
    display: flex;
    flex-direction: column; /* Ensures the content is laid out in a vertical column */
    justify-content: space-between; /* Maximizes space between top and bottom elements */

}

.text-above-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0 auto;
}

.percent-complete {
    margin-left: auto;
}

.search-status {
    color: lightgray;
    text-align: left;
    width: 50%;
    margin: 10px auto 0 auto;
}
.search-warn{
    margin-bottom:10%;
    color: #282828;
}