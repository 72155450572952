.landing-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:5%;
    padding: 50px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    padding: 20px;
}

.search-section {
    flex-basis: 100%;
}

.search-section h1 {
    font-size: 2.5rem;
    color: #333;
}

.search-section p {
    color: #666;
    margin-top: 10px;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
    gap: 10px;
}

.login-btn,
.get-started-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.login-btn {
    background: transparent;
    color: #333;
    border: 1px solid #333;
}
.login-btn:hover {
    background-color: #e5e5e5;
}

.get-started-btn {
    background: transparent;
    color: #ffffff;
    background-color: #007bff;
}
.get-started-btn:hover {
    background-color: #0056b3;
}

.image-section {
    width:400px;
    margin-right: 50px;
    flex-basis: 40%;
    background-color: #cccccc;

    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Add a background image or include an <img> tag */
}
.demo-image {
    height:100%;
    width:100%;
    /* Add a background image or include an <img> tag */
}

.company-logo {
    margin: 30px 0px 0px 40px;
    height: 75px;  /* Adjust based on your logo's size */
}
