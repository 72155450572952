.export-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20%;
}

.export-container {
    background: #ffffff;
    width: 40%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    text-align: center;
}

/* ... rest of the styles ... */

.export-message h2 {
    color: #333;
    margin-bottom: 15px;
}

.export-message p {
    color: #666;
    font-size: 14px;
    line-height: 1.4;
}

.download-btn {
    background-color: #0066ff;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.download-btn:hover {
    background-color: #0056e0;
}

/* Add responsiveness if needed */
@media (max-width: 600px) {
    .export-container {
        width: auto;
    }
}
